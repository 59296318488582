import React, { useEffect } from 'react'

import {
  useUser,
  useMapi,
  PackageCard,
  LinkButton,
  ModalWithState,
} from '@leshen/gatsby-theme-leshen'

import {
  VariableContent,
  Columns,
  Stack,
  scrollTo,
  Brandy,
  Typography,
} from '@leshen/ui'

import styled from '@emotion/styled'
import { css } from '@emotion/core'
import _get from 'lodash.get'

import useAvailablePlans from '../hooks/useAvailablePlans'
import useHasMounted from '../hooks/useHasMounted'

import zipLogic from '../utils/zipLogic'

import Spinner from './Spinner'

const ZipPackageSection = () => {
  const { userData, userLocation } = useUser()

  const mapiData = useMapi()
  const phoneNumber = _get(mapiData, 'rotatedNumber')

  // Store the userData in localStorage for persistance.
  const { availablePlans, setAvailablePlans, setUserData } =
    useAvailablePlans(userData)

  /**
   * Check that userData is not null, which is it's initial state.
   * userData being set means it's been called at least once, and
   * the loading variable shows that it's currently loading.
   *
   * Important Note: We cannot just check for userData loading
   * because that alone coming back false does not mean we want
   * to show the loading state. On initial page load, userData should
   * be null and we don't want to show a loading state then.
   *
   * This should be reworked in a big way, as there is a little too much
   * indirection for my taste, but there are a lot of moving parts that
   * aren't all accessible from this codebase so it will take some work.
   */
  const arePackagesLoading = userData && userData.loading

  useEffect(() => {
    /**
     * Commonly on page reload, `userData` will be null, and
     * we don't want to override localStorage with that data.
     * This does assume that `userData` will never be wiped
     * on purpose with the intent to wipe localStorage.
     * `null` is meant to be a starting point, and `userData`
     * should just be set to an empty object if the intent is
     * to clear `availablePlans`.
     */

    // this statement checks if maxmind found a zipcode and runs zip logic if so
    if (userLocation?.zipCode?.length === 5 && !userData) {
      ;(async () => {
        const packageData = await zipLogic(userLocation.zipCode)

        setUserData((previousUserData) => ({
          ...previousUserData,
          loading: false,
          getwindstreamPackages: packageData,
        }))
      })()
    }

    if (!userData && availablePlans) {
      return
    }
    setAvailablePlans(userData)
  }, [userData, setUserData, availablePlans, setAvailablePlans, userLocation])

  useEffect(() => {
    if (availablePlans) {
      scrollTo(`#loadingScrollTarget`)
    }
  }, [availablePlans])

  /**
   * Server-side rendering check to not have mismatching data
   * on the server, which causes a bad rehydration to layout
   * content incorrectly in some situations when using local storage.
   */
  const hasMounted = useHasMounted()
  if (!hasMounted) {
    return null
  }

  const haveGetwindstreamPackages =
    availablePlans &&
    availablePlans.getwindstreamPackages &&
    availablePlans.getwindstreamPackages.length > 0

  return (
    <>
      {arePackagesLoading && (
        <VariableContent
          className="packages"
          alignMainContent="center"
          mainContent={
            <>
              <Typography variant="h4">
                Searching packages in your area.
              </Typography>
              <Spinner />
            </>
          }
        />
      )}

      {/* Set up the section(s) that show the users packages or out of area message below the Hero */}
      {haveGetwindstreamPackages && (
        <VariableContent
          className="packages"
          alignMainContent="center"
          mainContent={
            <>
              <Typography variant="h2">
                Upgrade to Kinetic Internet or Home Phone
              </Typography>
              <Typography>
                Keep your life moving with Kinetic Internet by Windstream and
                surf, stream, and talk whenever you want, however long you want.
                With fast speeds and unlimited internet data and calls, you
                won’t need to stop or slow down ever again.
              </Typography>
              <Typography>
                <strong>
                  Showing best plan for {userLocation.city},{' '}
                  {/* eslint-disable-next-line */}
                  {userLocation.zipCode}{' '}
                  <StyledButtonLink
                    type="button"
                    onClick={() => scrollTo('h1')}
                  >
                    change
                  </StyledButtonLink>
                </strong>
              </Typography>
            </>
          }
        >
          <Stack spacing="xxl">
            <Columns>
              {availablePlans.getwindstreamPackages.map((data) => (
                <PackageCard
                  label={data.label}
                  packageData={{
                    ...data.brandy,
                  }}
                  key={data.label}
                  modal={
                    <ModalWithState
                      modalText="See offer details"
                      heading={data?.modalData?.heading}
                    >
                      {data?.modalData?.legal?.map((legalData) => (
                        <Brandy
                          key={legalData.reference}
                          text={legalData.text}
                          variant="legal"
                        />
                      ))}
                    </ModalWithState>
                  }
                  content={
                    <LinkButton to={`tel:${phoneNumber}`}>
                      {phoneNumber}
                    </LinkButton>
                  }
                />
              ))}
            </Columns>
          </Stack>
        </VariableContent>
      )}

      {/**
       * If userData is not null, and its `loading` property is set to false
       * by availabilitySubmit, then a fetch request has just completed.
       */}
      {availablePlans &&
        !availablePlans.loading &&
        availablePlans?.getwindstreamPackages === null && (
          <VariableContent
            mainContent={
              <>
                <Typography variant="h2">
                  {`We're having trouble locating service options for your area.`}
                </Typography>
                <p>
                  Give us a call and one of our Internet specialists can help
                  get you connected!
                </p>
              </>
            }
            alignMainContent="center"
          />
        )}
    </>
  )
}

const StyledButtonLink = styled.button(
  ({ theme }) => css`
    color: ${theme.colors.primary.base.hex};
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    display: inline;
    font-weight: bold;
  `
)

export default ZipPackageSection
